import Vue from 'vue'
import VueRouter from 'vue-router'
import inicio from '../views/inicio.vue'
import logIn from '../views/logIn.vue'
import listaproductos from '../views/productos/listaProductos.vue'
import misclientes from '../views/clientes/misClientes.vue'
import equiposcliente from '../views/clientes/equiposCliente.vue'
import pedidoscliente from '../views/clientes/pedidosCliente.vue'
import errorcom from '../views/ErrorCom.vue'
import misdocumentos from '../views/documentos/misDocumentos.vue'
import equiposestadistica1 from '../views/equipos/equiposEstadistica1'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'inicio-app',
    component: inicio
  },
  {
    path: '/login',
    name: 'login-app',
    component: logIn
  },
  {
    path: '/listaproductos',
    name: 'lista-productos',
    component: listaproductos
  },
  {
    path: '/misclientes',
    name: 'mis-clientes',
    component: misclientes
  },
  {
    path: '/equiposcliente',
    name: 'equipos-cliente',
    component: equiposcliente
  },
  {
    path: '/pedidoscliente',
    name: 'pedidos-cliente',
    component: pedidoscliente
  },
  {
    path: '*',
    name: 'errorcom',
    component: errorcom
  },
  {
    path: '/misdocumentos',
    name: 'mis-documentos',
    component: misdocumentos
  },
  {
    path: '/equiposestadistica1',
    name: 'equipos-estadistica1',
    component: equiposestadistica1
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//guard
router.beforeEach((to, from, next) => {
  if(to.path == '/'){
    next()
  }
  else{
    if (to.path !== '/login' && !localStorage.getItem('spx_localdata')){  
      next({ name: 'login-app' })
  } else{
    next()
  } 
  }
})



export default router
