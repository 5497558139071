<template>
  <main>
      <div style="background-color: darkgoldenrod; color: white;" v-if="!esonline" class="text-center">
        <i class="fa fa-exclamation-triangle">
        </i> Sin conexion a internet...
      </div>
        <table id="tabla1" class="table table-sm table-secondary table-borderless" >
          <thead>
            <tr>
              <th class="text-center">LISTA DE PRECIOS - BCV {{ factorbcv | currency }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="producto in productos" :key="producto.id">
              <td>
                  <div class="card border-left-primary">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                                <img style="width: 8rem;" 
                                :src="'img/'+producto.CodProd+'.jpg'"  
                                alt="..." />   
                            </div>
                            <div class="col">
                                <div class="text-primary">
                                  {{producto.CodProd}} - {{ producto.Descrip }}</div>
                                <div>{{ producto.PrecioIVA | currency }} Bs.</div>
                                <div>{{ parseFloat(producto.PrecioIVA)/factorbcv | currency }} $</div>
                                <div style="text-align: right;" class="text-dark"> <i class="fa fa-shopping-cart"></i> {{ tienepedido(producto.CodProd) }}</div>
                            </div>
                          </div>
                      </div>
                      <div class="card-footer text-dark">
                        <div style="text-align: right;">{{producto.Existen | currency({ fractionCount: 0 }) }}  {{producto.Unidad}} &nbsp;&nbsp;&nbsp;
                          <button @click="editarPedido(producto)" class="btn btn-outline-primary" type="button" data-bs-toggle="modal" data-bs-target="#ModalActivos"><i class="fa fa-plus"></i></button> 
                        </div> 
                      </div>
                      <div>
                      </div>
                  </div>              
               </td>
            </tr>
          </tbody>
        </table>
        <!-- Modal -->
        <div class="modal fade" id="ModalActivos" tabindex="-1" aria-labelledby="ModalActivosLabel" aria-hidden="true">
          <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-body text-center">
                      <img :src="'img/'+datospedido.CodProd+'.jpg'"  alt="..." />
                      <div class="text-primary">{{ datospedido.Descrip }}</div>
                      <h4>{{ parseFloat(datospedido.PrecioIVA)/factorbcv | currency }} $</h4>
                      <div class="form-floating mt-2 mb-1">
                        <input v-model="cantidad" type="number" class="form-control" id="floatingInput" placeholder="CANTIDAD" required>
                        <label for="floatingInput">Cantidad</label>
                      </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="nuevodoPedido()"  type="button" class="btn btn-primary" data-bs-dismiss="modal">Agregar</button>
                        <button  type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>
                    </div>
          </div>
        </div>
        <!--- FIN MODAL --->
  </main>
  </template>
  
  <script>
  import "jquery/dist/jquery.min.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import $ from "jquery";
  
  export default {
    name: "ProductosCom",
    data(){
      return{
          productos:[],
          esonline : false,
          factorbcv: 0,
          datospedido : {},
          pedidotemp : [],
          codvend : null,
          cantidad : null,
          conexion : '',
          comentario : '',
          imagen : null,
          cantire : 0,
      }
    },
    mounted(){
      this.esonline=navigator.onLine;
      let datoslocales = JSON.parse(localStorage.getItem('spx_localdata'));
      this.codvend = datoslocales.spx_use_v;
      let strFactor = localStorage.getItem('spx_factorbcv');
      this.factorbcv = parseFloat(strFactor.substring(1,strFactor.length - 3));
      let pedidotemporal = JSON.parse(localStorage.getItem('spx_pedidotemp'));  
      if(pedidotemporal){ 
        this.pedidotemp = JSON.parse(localStorage.getItem('spx_pedidotemp'))  
        this.cantire = this.pedidotemp.length
      }
      this.listaProductos()
    },
    methods: {
      listaProductos(){
        this.productos = JSON.parse(localStorage.getItem('spx_priceslist'));
        if(this.productos != null){
          setTimeout(() => {
            $("#tabla1").DataTable(this.tablaDinamica(true,true,false))
          })
        }
      },
      nuevodoPedido(){
            this.pedidotemp.push({
                codvend :  this.codvend,
                codclie : '',
                cliente : '',
                codprod : this.datospedido.CodProd,
                descrip : this.datospedido.Descrip,
                cantidad : this.cantidad,
                precio : this.datospedido.PrecioIVA,
                comentario : this.comentario,
                conexion : this.conexion,
                deposito : this.datospedido.Deposito 
            })
            localStorage.setItem('spx_pedidotemp',JSON.stringify(this.pedidotemp))
            this.cantire = this.pedidotemp.length
            //this.tienepedido(this.datospedido.CodProd)
        },
        editarPedido(producto){
          this.cantidad = null
          this.datospedido = producto;
        },
        tienepedido(codigo){
          let cantidadpedida = 0
          if(this.cantire>0){
              //console.log(codigo)
              for (let i =0 ; i<=this.cantire-1; i++){
                //console.log(this.pedidotemp[i].codprod)
                if(this.pedidotemp[i].codprod == codigo){
                  cantidadpedida=cantidadpedida+Number(this.pedidotemp[i].cantidad)
                }
              }
          }
          return cantidadpedida
        },
    },
  }  
  </script>