<template>
  <div id="app">
    <div class="collapse" id="navbarToggleExternalContent">
      <br><br>     
      <div>
        <div class="list-group">
          <router-link to="/misdocumentos" class="list-group-item list-group-item-action"><i class="fa fa-truck"></i> Mis Pedidos</router-link>
          <router-link to="/equiposestadistica1" class="list-group-item list-group-item-action"><i class="fa fa-bar-chart"></i> Estadistica Equipos Frios</router-link>
          <div v-if="userlog">
            <a href="#" class="list-group-item list-group-item-action" @click="loguot()"><i class="fa fa-sign-out"></i> Cerrar Sesion</a>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-light bg-light fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <router-link class="btn btn-outline-info" to="/"><i class="fa fa-home"></i></router-link>
        <router-link class="btn btn-outline-info" to="/listaproductos"><i class="fa fa-list"></i></router-link >
        <router-link class="btn btn-outline-info" to="/pedidoscliente"><i class="fa fa-shopping-cart"></i></router-link>
        <router-link class="btn btn-outline-info" to="/misclientes"><i class="fa fa-users"></i></router-link>
      </div>
    </nav>
    <br><br><p></p>
    <router-view/>
  </div>
</template>

<script>
 export default {
      name : "app",
      data () {
        return {
          usuario : null,
          vendedor : null,
          userlog : false,
        }
      },
      mounted(){
        if(localStorage.getItem('spx_localdata')){
          let datoslocales = JSON.parse(localStorage.getItem('spx_localdata'));
          this.usuario = datoslocales.spx_use_p;
          this.vendedor = datoslocales.spx_use_v;
          this.userlog = true;
        }
      },
      methods : {
        loguot(){
          localStorage.removeItem('spx_localdata')
          localStorage.removeItem('spx_factorbcv')
          localStorage.removeItem('spx_fechareplica')
          localStorage.removeItem('spx_mydata')
          location.reload()
        },
      }
  };

</script>

<style>

</style>
