<template>
  <main>
    <div style="background-color: darkgoldenrod; color: white;" v-if="!esonline" class="text-center">
        <i class="fa fa-exclamation-triangle">
        </i> Sin conexion a internet...
      </div>
        <div class="input-group p-2">
            <input v-model="datoabuscar" type="text" class="form-control bg-light border-0 small" placeholder="Buscar por nombre"
                aria-label="Search" aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click="listaClientes()">
                    <i class="fas fa-search fa-sm"></i>
                </button>
            </div>
        </div>
    <div class="mt-2">
      <div v-for="cliente in datosPaginados" :key="cliente.id" class="card text-center" style="margin-bottom: 6px;">
        <div class="card-header">
          <ul class="nav nav-pills card-header-pills">
            <li class="nav-item">
              <a class="btn btn-sm btn-primary" style="margin-right: 3px;" href="#">Censo</a>
            </li>
            <li class="nav-item">
              <router-link  
                :to="{name:'equipos-cliente',params:{codclie:cliente.codclie,descrip:cliente.descrip}}"    
                class="btn btn-sm btn-primary" 
                style="margin-right: 3px;">  Equipos Frios
              </router-link>
            </li>
            <li class="d-flex">
              <router-link  
                  :to="{name:'pedidos-cliente',params:{codclie:cliente.codclie,descrip:cliente.descrip}}"
                  class="btn btn-sm btn-primary" href="#">Pedido
            </router-link>
            </li>
          </ul>
        </div>
        <p class="text-primary" style="margin-top: 2px;"> <small>{{ cliente.descrip }}</small></p>
        <div v-if="cliente.escxc>0">
          <table class="table table-sm">
            <tr v-for="documento in cliente.porcobrar" :key="documento.numerod">
              <td>
                <small>
                  <i v-if="documento.TipoCxc=='10'">Fac: </i>  <i v-else>ND: </i> 
                  {{ documento.NumeroD }}  
                  <!-- i v-if="documento.TipoCxc=='10'" class="text tex-sm">{{ documento.FactorFac | currency }} - {{ documento.Dias }}</i -->  
                </small>
              </td>
              <td style="text-align: right;">
                <div v-if="documento.TipoCxc=='10'" >
                <small> {{ documento.SaldoDol | currency }} $</small>
                </div>
              </td>
              <td style="text-align: right;">
                <div v-if="documento.TipoCxc=='10'" >
                  <small> {{ documento.MtoTotal | currency }} Bs</small>
                </div>
                <div v-else>
                  <small> {{ documento.saldo | currency }} Bs</small>
                </div>
              </td>
            </tr>
            <tr v-if="cliente.escxc>1">
              <td> <small><b>Total</b></small></td>
              <td style="text-align: right;"><small><b>{{ cliente.totalcxcdol | currency }} $</b></small></td>
              <td style="text-align: right;"><small><b>{{ cliente.totalcxc | currency }} Bs</b></small></td>
            </tr>
          </table>
        </div>
      </div>
        <!--table class="table table-sm table-light table-borderless">
          <tbody>
            <tr v-for="cliente in datosPaginados" :key="cliente.id">
              <td>
                <div class="card"> 
                  <div class="card-header">
                    <div class="card-title">Card Title</div>
              <div class="card-tools">
                <button
                  class="btn btn-primary btn-sm"
                >
                  <i className="fa fa-edit"></i>
                </button>
              </div>
                    <div class="form-group">
                      <router-link 
                      :to="{name:'pedidos-cliente',params:{codclie:cliente.codclie,descrip:cliente.descrip}}"
                      style="color: #cecece;"><i class="fa fa-cart-plus"></i></router-link>
                      <router-link  
                      :to="{name:'equipos-cliente',params:{codclie:cliente.codclie,descrip:cliente.descrip}}"
                      style="color: #cecece;"><i class="fa fa-tachometer"></i></router-link >
                    </div>
                  </div>
                      <p class="m-1 font-weight-bold text-primary">
                      <small>{{ cliente.descrip }}</small>
                      </p>
                    
                    <div v-if="cliente.escxc>0">
                        <table class="table table-sm">
                          <tr v-for="documento in cliente.porcobrar" :key="documento.numerod">
                            <td>
                             <small>
                              <i v-if="documento.TipoCxc=='10'">Fac: </i>
                              <i v-else>ND: </i> 
                              {{ documento.NumeroD }} <br> 
                              <i v-if="documento.TipoCxc=='10'" class="text tex-sm">{{ documento.FactorFac | currency }} - {{ documento.Dias }}</i>  
                            </small>
                            </td>
                            <td class="text-right">
                              <div v-if="documento.TipoCxc=='10'" >
                              <small> {{ documento.SaldoDol | currency }} $</small>
                              </div>
                            </td>
                            <td class="text-right">
                              <div v-if="documento.TipoCxc=='10'" >
                                <small> {{ documento.MtoTotal | currency }} Bs</small>
                              </div>
                              <div v-else>
                                <small> {{ documento.saldo | currency }} Bs</small>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="cliente.escxc>1">
                            <td> <small><b>Total</b></small></td>
                            <td class="text-right"><small><b>{{ cliente.totalcxcdol | currency }} $</b></small></td>
                            <td class="text-right"><small><b>{{ cliente.totalcxc | currency }} Bs</b></small></td>
                          </tr>
                        </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table -->
        <nav v-if="this.totalPaginas() > 1 && this.totalPaginas() < 6" aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item"><a class="page-link" @click="getPaginaPrevia()"><i class="fa  fa-chevron-circle-left"></i></a></li>
            <li v-for="pagina in  totalPaginas()" :key="pagina" class="page-item" :class="esActiva(pagina)"><a class="page-link" @click="getDataPagina(pagina)" >{{ pagina }}</a></li>
            <li class="page-item"><a class="page-link" @click="getPaginaProxima()"><i class="fa  fa-chevron-circle-right"></i></a></li>
          </ul>
        </nav>
        <nav v-if="this.totalPaginas() > 25" aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">DATOS EXCEDEN</li>
          </ul>
        </nav>
    </div>
  </main>
  </template>
  
  <script>
  export default {
    name: "ClienteCom",
    data(){
      return{
          esonline : false,
          datoabuscar : null,
          todosClientes : [],
          clientes:[],
          elementosPorPagina : 5,
          datosPaginados : [],
          paginaActual : 1,
      }
    },
    mounted(){
      this.esonline=navigator.onLine
      this.todosClientes = JSON.parse(localStorage.getItem('spx_clientlist'));
    },
    methods: {
      listaClientes(){
        this.clientes = this.todosClientes.filter(
          cliente => cliente.descrip.toLowerCase().includes(this.datoabuscar.toLowerCase())
        )
        this.totalPaginas()
        this.getDataPagina(1)
      },
      totalPaginas(){
        return Math.ceil(this.clientes.length / this.elementosPorPagina)
      },
      getDataPagina(noPagina){
        this.paginaActual = noPagina;
        this.datosPaginados = [];
        let ini = (noPagina*this.elementosPorPagina) - this.elementosPorPagina
        let fin = (noPagina*this.elementosPorPagina)
        this.datosPaginados = this.clientes.slice(ini,fin);
      },
      getPaginaPrevia(){
        if(this.paginaActual>1){
          this.paginaActual--
        }
        this.getDataPagina(this.paginaActual)
      },
      getPaginaProxima(){
        if(this.paginaActual<this.totalPaginas()){
          this.paginaActual++
        }
        this.getDataPagina(this.paginaActual)
      },
      esActiva(noPagina){
        return noPagina ==  this.paginaActual ? 'active' : '';
      }
    },
  }  
  </script>
