<template>
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
                      <div class="row">
                          <div class="col-lg-12">
                              <div v-if="esonline" class="p-5">
                                <main class="animate__animated animate__fadeInDown">
                                  <div class="text-center"><img src="../assets/spx.png" alt="spx"></div>
                                  <div class="text-center">
                                      <h1 class="h4 text-gray-900 mb-4">Pedidos</h1>
                                  </div>
                                </main>
                                  <alert-com :textomen="textomen" :colormen="colormen" @alcerrar="deshabilita" />
                                  <main class="animate__animated animate__backInUp">
                                  <form class="user" @submit.prevent="submit"> 
                                    <div class="form-floating mb-3">
                                      <input v-model="parametros.usuario" type="text" class="form-control" id="floatingInput" placeholder="USUARIO SAINT" maxlength="10" required>
                                      <label for="floatingInput">Usuario</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                      <input v-model="parametros.password" type="password" class="form-control" id="floatingPassword" placeholder="Password" required>
                                      <label for="floatingPassword">Password</label>
                                    </div>   
                                    <div class="form-floating d-grid gap-2">
                                       <button type="submit" class="btn btn-primary btn-user btn-block">INGRESAR</button>
                                    </div>
                                    <hr>
                                  </form>
                                  <div class="text-center">
                                      <a href="#">Solicitar Registro</a>
                                  </div>
                                  <div class="text-center">
                                      <i class="small" href="#">ninsistemas@hotmail.com</i>
                                  </div>
                                  </main>
                              </div>
                              <div v-else class="alert alert-secondary" style="text-align: center;">
                                <hr><br>
                                <div style="background-color: darkgoldenrod; color: white;" v-if="!esonline" class="text-center">
                                  <i class="fa fa-exclamation-triangle">
                                  </i> Sin conexion a internet...
                                </div>
                                <div>
                                  <i class="fa fa-user-times"></i> Para aperturar la sesion la aplicacion debe estar OnLine
                                </div>
                              </div>
                          </div>
                      </div>

          </div>
      </div>
  </div>
  </template>
  
  <script>
     import AlertCom from '../components/AlertCom.vue';
     import axios from 'axios';
     import {Global} from '../shared/Global';
     export default {
          name : "logIn",
          components : {
            AlertCom
          },
          data(){
              return {
                esonline : false,
                textomen : false,
                colormen : '',
                parametros:{
                    usuario : '',
                    password : '',
                },
              }
          } ,
          mounted(){
            this.esonline=navigator.onLine
          },
          methods: {
              submit () {
                if(this.usuariologeado){
                  this.$router.push('/')
                }
                else{
                  let config={
                    headers:{
                      'Accept' : '*/*'
                    }
                  }
                  axios.post(Global.url+'nrsrclassl',this.parametros,config)
                  .then((response)=>{
                    this.textomen=response.data.message
                    if(response.data.response=='ok'){
                          this.colormen='primary'
                          let spx_localdata = {
                            spx_tok_p : response.data.token,
                            spx_nam_p : response.data.usuario,
                            spx_use_p : response.data.nombre,
                            spx_use_v : response.data.codvend,
                          }
                          let spx_factorbcv = 0;
                          let spx_fechareplica = null;
                          localStorage.setItem('spx_localdata',JSON.stringify(spx_localdata))
                          localStorage.setItem('spx_factorbcv',JSON.stringify(spx_factorbcv))
                          localStorage.setItem('spx_fechareplica',JSON.stringify(spx_fechareplica))
                          this.$router.push('/')
                          location.reload('/')
                      }
                      else{
                          this.colormen='danger'
                          console.log(this.textomen)
                      }
                  })
                  .catch(function(error){
                      console.log(error)
                  })
                }
              },
              deshabilita() {
                this.textomen = false
              }
            },
      };
  
  </script>

<style>
body {
  align-items: center;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
