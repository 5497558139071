<template>
<main>
  <div class="container">
      <div id="chart">
        <apexchart type="bar" height="430" :options="chartOptions" :series="series"></apexchart>
      </div>
  </div>
  </main>
  </template>
  
  <script>
  import axios from 'axios'
  import { Global } from '../../shared/Global'
  import VueApexCharts from "vue-apexcharts"
  
  
  export default {
    name: 'equiposestadistica1',
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        datos : [],
        series: [{ data: [] }],
        chartOptions: {
            chart: {
              type: 'bar',
              height: 300
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                dataLabels: {
                  position: 'bottom'
                },
              }
            },
            colors: ['#d4526e'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#000000']
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            xaxis: {
              categories: ['TOTAL EQUIPOS', 'ACTIVOS', 'INACTIVOS', 'FEMSA', 'CONTAMINADOS'],
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            title: {
                text: 'Equipos por Estatus',
                align: 'center',
                floating: true
            },
          },
      }
    },
    mounted(){
      this.getEstadisticasEquipos1()
    },
    methods: {
      getEstadisticasEquipos1(){      
            axios.get(Global.url+'equipos/estadisticauno',this.headRequest())
            .then(res=>{
                if(res.data.response!="error"){ 
                    this.datos = [
                      res.data[0].total_equipos,
                      res.data[0].equipos_activos,
                      res.data[0].equipos_inactivos,
                      res.data[0].productos_femsa,
                      res.data[0].equipos_contaminados
                    ];
                    this.series = [{ data: this.datos }]
                }
                else{
                    console.log(res.data.message)
                }
            }
            )
            .catch(function(error){
                console.log(error)
            })
            
        },
    },
  }
  </script>