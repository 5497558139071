<template>
  <div class="main">
    <div style="background-color: darkgoldenrod; color: white;" v-if="!esonline" class="text-center">
        <i class="fa fa-exclamation-triangle"></i> Sin conexion a internet...
    </div>
    <div v-if="essesion">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-user"></i> - {{ usuario }} - {{ vendedor }}</li>
            <li class="list-group-item" :style=this.colorfond><i class="fa fa-bank"></i> - BCV: {{ factorbcv | currency }} - Act: {{ fecharepl }}</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-calendar"></i> - Dias Habiles {{ misDatos.dias_habiles | currency({ fractionCount: 0 }) }} - Trabajados {{ misDatos.dias_trabajados | currency({ fractionCount: 0 }) }}</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-bar-chart"></i> - Acumulado {{ misDatos.cajas_venta_mes | currency({ fractionCount: 0 }) }}</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-bar-chart"></i> - Probable {{ misDatos.probable  | currency({ fractionCount: 0 }) }}</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-bar-chart"></i> - Cuota Mes {{ misDatos.cajas_cuota_mes  | currency({ fractionCount: 0 }) }}</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-pie-chart"></i> - Alcance {{ misDatos.alcance }} %</li>
            <li class="list-group-item" :style=this.colortext><i class="fa fa-asterisk"></i> - Probable vs Cuota: {{ misDatos.probable_vs_cuota }} %</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-cubes"></i> - CF x Vender: {{ misDatos.cf_vender  | currency({ fractionCount: 0 }) }}</li>
            <li class="list-group-item" style="color: darkslategray;"><i class="fa fa-cubes"></i> - Cuota del Dia: {{ misDatos.cuota_dia  | currency({ fractionCount: 0 }) }}</li>
          </ul>
          <div v-if="mensajeer!='ok'" class="alert alert-danger p-2">{{ this.mensajeer }}</div>
          <div v-if="esonline">
            <!-- div v-if="!sincroniza" class="d-grid gap-2">
                <button @click="sincronizarMisdatos()" class="btn btn-primary btn-sm btn-block" >SINCRONIZAR MIS DATOS</button>
            </div -->
            <div v-if="sincroniza" class="d-grid gap-2">
                <button class="btn btn-primary btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    SINCRONIZANDO...
                </button>
            </div>
          </div>
    </div>
    <div v-else class="alert alert-light text-center">
      <img src="../assets/logo.png" alt="...">
      <i class="fa fa-user-times"></i> Sesion cerrada.<br> Para acceder a mas informacion debes <router-link to="/login" ><i class="fa fa-sign-in"></i> ingresar</router-link> tus datos.
    </div>
  </div>
</template>
<script>
    import axios from 'axios'
    import { Global } from '../shared/Global'
    export default {
      name : "inicio-app",
      data(){
        return {
          esonline : false,
          essesion : false,
          sincroniza : false,
          vendedor : null,
          usuario : null,
          misDatos : {
                cajas_venta_mes : 0,
                cajas_cuota_mes : 0,
                dias_habiles : 0,
                dias_trabajados : 0,
                probable : 0,
                alcance : 0,
                probable_vs_cuota : 0,
                cf_vender : 0,
                cuota_dia : 0,
            },
            factorbcv : 0,
            fecharepl : null,
            colorfond : '',
            colortext : '',
            mensajeer : 'ok',
        }
      },
      mounted(){
        this.esonline=navigator.onLine
        let datoslocales = JSON.parse(localStorage.getItem('spx_localdata'));
        if(datoslocales){ 
          this.essesion=true
          this.usuario = datoslocales.spx_use_p;
          this.vendedor = datoslocales.spx_use_v;
          this.factorbcv = localStorage.getItem('spx_factorbcv');
          this.fecharepl = localStorage.getItem('spx_fechareplica');
          //this.getMisDatos();
          this.sincronizarMisdatos(); 
        }
      },
      methods : {
        getMisDatos(){
            if(localStorage.getItem('spx_mydata')){
                this.misDatos = JSON.parse(localStorage.getItem('spx_mydata'));
                if (this.misDatos.probable_vs_cuota < 0){
                    this.alerta = "color: black; background-color:  #f94e4e ;";
                }
                this.colorband();
            }
        },
        sincronizarMisdatos(){
            this.sincroniza=true
            this.mensajeer ='ok'
            this.MisDatos();
            this.Productos();
            this.Clientes();
            this.Pedidos();
            this.factorcambiario();
            //this.Equipos()
            this.fechareplica();
        },
        MisDatos(){           
             axios.get(Global.url+'consultabrujula/'+this.vendedor,this.headRequest())
            .then(res=>{
                if(res.data.response!="error"){ 
                    localStorage.removeItem('spx_mydata');
                    this.misDatos.cajas_venta_mes 
                    this.misDatos.cajas_venta_mes = res.data[0]['cajas_venta_mes'];
                    this.misDatos.cajas_cuota_mes = res.data[0]['cajas_cuota_mes'];
                    this.misDatos.dias_habiles = res.data[0]['dias_habiles'];
                    this.misDatos.dias_trabajados = res.data[0]['dias_trabajados'];
                    this.misDatos.probable = res.data[0]['probable'];
                    this.misDatos.alcance = res.data[0]['alcance'];
                    this.misDatos.probable_vs_cuota = res.data[0]['probable_vs_cuota'];
                    this.misDatos.cf_vender = res.data[0]['cf_vender'];
                    this.misDatos.cuota_dia = res.data[0]['cuota_dia'];
                    localStorage.setItem('spx_mydata',JSON.stringify(this.misDatos))
                    if(this.misDatos.probable_vs_cuota<0){ this.colortext='background-color: darksalmon' }
                }
                else{
                    this.mensajeerror(res.data.message) 
                }
            }
            )
            .catch(function(error){
                this.mensajeerror('respuesta erronea - '+error);
            })
        },
        Productos(){
            localStorage.removeItem('spx_priceslist');
            axios.get(Global.url+'exproductos',this.headRequest())
            .then(res=>{
                if(res.data.response!="error"){
                    let productos = res.data
                    localStorage.setItem('spx_priceslist',JSON.stringify(productos))
                }
                else{
                    this.mensajeerror(res.data.message) 
                }
            }
            )
            .catch(function(error){
                this.mensajeerror('respuesta erronea - '+error);
            });
        },
        Clientes(){
            localStorage.removeItem('spx_clientlist');
            localStorage.removeItem('spx_updateclient');
            axios.get(Global.url+'clientesvendedor/'+this.vendedor,this.headRequest())
            .then(res=>{
                if(res.data.response!="error"){
                    let clientes = res.data
                    localStorage.setItem('spx_clientlist',JSON.stringify(clientes))
                }
                else{
                    this.mensajeerror(res.data.message) 
                }
            }
            )
            .catch(function(error){
                this.mensajeerror('respuesta erronea - '+error);
            })
        },
        Pedidos(){
            localStorage.removeItem('spx_orderslist');
            axios.get(Global.url+'documento/pendientes/'+this.vendedor,this.headRequest())
            .then(res=>{
                if(res.data.response!="error"){
                    let pedidos = res.data
                    localStorage.setItem('spx_orderslist',JSON.stringify(pedidos))
                }
                else{
                    this.mensajeerror(res.data.message) 
                }
            }
            )
            .catch(function(error){
                this.mensajeerror('respuesta erronea - '+error);
            });
            this.fechareplica();
        },
        factorcambiario(){
            localStorage.removeItem('spx_factorbcv')
            axios.get(Global.url+'factorcambiario',this.headRequest())
            .then(res=>{
                if(res.data.response!="error"){ 
                    this.factorbcv = res.data[0]['FactorAct']
                    localStorage.setItem('spx_factorbcv',JSON.stringify(this.factorbcv))
                }
                else{
                    this.mensajeerror(res.data.message) 
                }
            }
            )
            .catch(function(error){
                this.mensajeerror('respuesta erronea - '+error);
            })
            },
            Equipos(){
                localStorage.removeItem('spx_equipment');
                axios.get(Global.url+'equipos',this.headRequest())
                .then(res=>{
                    if(res.data.response!="error"){
                        let equipos = res.data
                        localStorage.setItem('spx_equipment',JSON.stringify(equipos))
                    }
                    else{
                        this.mensajeerror(res.data.message) 
                    }
                }
                )
                .catch(function(error){
                    this.mensajeerror('respuesta erronea - '+error);
                });
            },
            fechareplica(){
                axios.get(Global.url+'configurador/parametros',this.headRequest())
                .then(res=>{
                    if(res.data.response!="error"){ 
                        let fecharespu = res.data[0]['fecha_replica'] 
                        this.fecharepl =fecharespu.substring(8,10)+'/'+fecharespu.substring(5,7)+'/'+fecharespu.substring(0,4)+' '+fecharespu.substring(11,16);
                        localStorage.removeItem('spx_fechareplica')
                        localStorage.setItem('spx_fechareplica',this.fecharepl)
                        this.sincroniza=false
                        this.colorband()
                    }
                    else{
                        this.mensajeerror(res.data.message) 
                    }
                }
                )
                .catch(function(error){
                    this.mensajeerror('respuesta erronea - '+error);
                });
            },
            colorband(){
                let today = new Date();
                let now = today.getDate().toString().padStart(2, "0")+'/'+(today.getMonth() + 1).toString().padStart(2, "0")+'/'+today.getFullYear();
                if (now != this.fecharepl.substring(0,10)){
                    this.colorfond = 'background-color: darksalmon';
                }
            },
            mensajeerror(error){
                this.mensajeer = error
                this.sincroniza=false
            },
      },
      
  };

</script>
