<template>
    <main>
        <div style="background-color: darkgoldenrod; color: white;" v-if="!esonline" class="text-center">
        <i class="fa fa-exclamation-triangle">
        </i> Sin conexion a internet...
      </div>
        <div class="text-sm text-center">Equipos asignados a: <br/>{{ cliente.codclie }} - {{ cliente.descrip }}</div>
        <div v-if="esonline" style="text-align: right;">
            <button @click="nuevoEquipo()" class="btn btn-primary btn-block" style="margin-bottom: 3%;margin-top: 2%;" data-bs-toggle="modal" data-bs-target="#ModalActivos">Agregar</button>
        </div>
        <div v-for="equipo in equipos" :key="equipo.id" class="card text-center">
            <div class="card-header">
                <ul class="nav nav-pills card-header-pills">
                <li class="nav-item">
                    <a class="nav-link disabled" aria-disabled="true">id: {{ equipo.id }}</a>
                </li>
                <li v-if="esonline" class="nav-item">
                    <button @click="editarEquipo(equipo)" type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#ModalActivos" style="margin-right: 6px;">
                    <i class="fa fa-pencil"></i>
                </button>
                </li>
                <li v-if="esonline" class="nav-item">
                    <button @click="borraEquipo(equipo)" type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#ModalActivos">
                    <i class="fa fa-trash"></i>
                </button>
                </li>
                </ul>
            </div>
            <div class="card-body">
                <h6 class="card-title">Puertas: {{ equipo.puertas }} - Serial: {{ equipo.serial }}</h6>
                <div v-if="equipo.activo==1"> 
                    <i style="color: green;">Activo</i>
                    <i v-if="equipo.productos_femsa==1" style="color: green;"> - Femsa</i>
                    <i v-if="equipo.contaminado==1" style="color: red;"> - Contaminado</i> 
                </div>
                <div v-else> 
                    <i style="color: red;">Inactivo</i>  
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="ModalActivos" tabindex="-1" aria-labelledby="ModalActivosLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ModalActivosLabel">{{ titulomodal }}</h5>
                <button type="button" class="btn btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div v-if="titulomodal=='ELIMINAR EQUIPO'" class="text-center">
                    <b>REALMENTE QUIERE ELIMINAR EL EQUIPO</b> 
                </div>
                <div v-else>

                    <input v-model="datosequipo.serial" type="text" maxlength="20" class="form-control mb-2" placeholder="SERIAL">

                    <select v-model="datosequipo.puertas" class="form-select mb-2">
                        <option value="1">1 Puerta</option>
                        <option value="2">2 Puertas</option>
                        <option value="3">3 Puertas</option>
                    </select>


                    <select v-model="datosequipo.activo" class="form-select mb-2">
                        <option value="1">Activo</option>
                        <option value="0">Inactivo</option>
                    </select>

                    <select v-model="datosequipo.productos_femsa" class="form-select mb-2">
                        <option value="1">Contine productos FEMSA</option>
                        <option value="0">NO tiene productos FEMSA</option>
                    </select>

                    <select v-model="datosequipo.contaminado" class="form-select">
                        <option value="0" default>No esta contaminado</option>
                        <option value="1">EQUIPO CONTAMINADO</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <div v-if="titulomodal=='Nuevo Equipo'">
                <button @click="agregarEquipo()" type="button" class="btn btn-primary" data-bs-dismiss="modal">Agregar</button>
                </div>
                <div v-if="titulomodal=='Editar Equipo'">
                    <button @click="actualizarEquipo()" type="button" class="btn btn-success" data-bs-dismiss="modal">Actualizar</button>
                </div>
                <div v-if="titulomodal=='ELIMINAR EQUIPO'">
                    <button @click="eliminarEquipo()" type="button" class="btn btn-warning" data-bs-dismiss="modal">ELIMINAR</button>
                </div>
            </div>
            </div>
            </div>
        </div>
        <!--- FIN MODAL --->
    </main>
</template>

<script> 
import axios from 'axios'
import { Global } from '../../shared/Global'

export default {
  name: "EquiposCliente",
  data(){
    return{
        esonline : false,
        cliente : {},
        equipos:[],
        datosequipo : {},
        titulomodal : "",
        indice: 0,
        indext: 0,
    }
  },
  created(){
    if(this.$route.params.codclie){
        this.cliente = {
            codclie : this.$route.params.codclie,
            descrip : this.$route.params.descrip
        };
    }
  },
  mounted(){
    this.esonline=navigator.onLine
    this.listaEquipos();
  },
  methods: {
    titulo(des){ this.titulomodal=des; },
    listaEquipos(){
        axios.get(Global.url+'equipos/cliente/'+this.cliente.codclie,this.headRequest())
        .then(res=>{
            if(res.data.response!="error"){
                this.equipos = res.data
            }
            else{
                console.log(res.data.message)
            }
          }
        )
        .catch(function(error){
            console.log('respuesta erronea - '+error)
        });
    
    },
    nuevoEquipo(){
        this.titulomodal='Nuevo Equipo';
        this.datosequipo = {
            id: "",
            cliente: this.cliente.codclie,
            serial: "",
            puertas: 1,
            activo: 1,
            productos_femsa: 1,
            contaminado: 0,
            nrounico:0
        };
    },
    agregarEquipo(){
        axios.post(Global.url+'equipos',this.datosequipo,this.headRequest())
        .then(res=>{
            if(res.data.response!="error"){
                this.listaEquipos();
            }
            else{
                console.log(res.data.message)
            }
          }
        )
        .catch(function(error){
            console.log('respuesta erronea - '+error)
        });
    },
    editarEquipo(equipo){
        this.titulomodal='Editar Equipo';
        this.datosequipo = equipo;
    },
    actualizarEquipo(){
        axios.put(Global.url+'equipos',this.datosequipo,this.headRequest())
        .then(res=>{
            if(res.data.response!="error"){
                this.listaEquipos();
            }
            else{
                console.log(res.data.message)
            }
          }
        )
        .catch(function(error){
            console.log('respuesta erronea - '+error)
        }); 
    },
    borraEquipo(equipo){
        this.titulomodal='ELIMINAR EQUIPO';
        this.datosequipo = equipo;
    },
    eliminarEquipo(){
        axios.post(Global.url+'equipos/codigoe',this.datosequipo,this.headRequest())
        .then(res=>{
            if(res.data.response!="error"){
                this.listaEquipos();
            }
            else{
                console.log(res.data.message)
            }
          }
        )
        .catch(function(error){
            console.log('respuesta erronea - '+error)
        }); 
    },
  },
}  
</script>