<template>
    <main>
        <div style="background-color: darkgoldenrod; color: white;" v-if="!esonline" class="text-center">
        <i class="fa fa-exclamation-triangle">
        </i> Sin conexion a internet...
      </div>
        <div v-if="this.cantire>0" class="container mt-2">
                <div v-if="codclie" style="text-align: center;"><h5>{{ cliente.codclie }} - {{ cliente.descrip }}</h5></div>
                <div class="row g-2 mt-1">
                    <table class="table table-sm">
                        <tbody>
                            <tr v-for="(item,index) in pedidotemp" :key="index">
                                <td><small>{{ item.codprod }} - {{ item.descrip }}<br>
                                Cant: {{ item.cantidad }} - Precio: {{ item.precio | currency }}</small></td>
                                <td style="text-align: right;"><small>{{ item.cantidad*item.precio | currency }}</small></td>
                                <td><button  @click="borraoitem(index)" class="btn btn-sm  btn-outline-dark btn-circle"><i class="fa fa-trash"></i></button></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="table-secondary">
                                <td>SUB TOTAL</td>
                                <td style="text-align: right;"><small><b>{{ this.totalre | currency }}</b></small></td>
                                <td></td>
                            </tr>
                            <tr class="table-secondary">
                                <td>IVA 16% </td>
                                <td style="text-align: right;"><small><b>{{ this.totalre*0.16 | currency }}</b></small></td>
                                <td></td>
                            </tr>
                             <tr class="table-secondary">
                                <td>TOTAL</td>
                                <td style="text-align: right;"><small><b>{{ this.totalre+(this.totalre*0.16) | currency }}</b></small></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <!-- input v-model="comentario"  type="text" class="form-control mb-2" placeholder="Comentario"  maxlength="40" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z 1234567890.,ñÑ]/, uppercase: true }} }" -->
                 <div v-if="codclie" class="d-grid gap-2">
                    <button @click="totalizarpedido()" class="btn btn-primary mb-1 btn-block"  >GUARDAR Y ENVIAR</button>
                    <button @click="guardarPedido()" class="btn btn-dark mb-1 btn-block"  >GUARDAR</button>
                    <button @click="borrarPedido()" class="btn btn-secondary btn-block" type="button" >BORRAR PEDIDO</button>
                </div>
                <div v-else style="text-align: center;">
                    <button @click="borrarPedido()" class="btn btn-secondary btn-block" type="button" >BORRAR PEDIDO</button>
                </div>
        </div>
        <div v-else >
            <h5 style="text-align: center;">NO HAY PRODUCTOS PEDIDOS</h5>
        </div>
    </main>
</template>

<script>
import axios from 'axios'
import { Global } from '../../shared/Global'

export default {
    data(){
        return {
            esonline : false,
            cliente : {},
            iddelet : { id : null },
            pedidotemp : [],
            codclie : null,
            comentario : '',
            cantire : 0,
            totalre : 0,
        }
    },
    created(){
        this.esonline=navigator.onLine
        this.pedidotemp = JSON.parse(localStorage.getItem('spx_pedidotemp'))
        if(this.pedidotemp){
            this.cantire = this.pedidotemp.length;
            this.calculatotal();
            if(this.$route.params.codclie){
                this.cliente = {
                    codclie : this.$route.params.codclie,
                    descrip : this.$route.params.descrip
                }
                this.codclie = this.$route.params.codclie
                //console.log(this.codclie)
                for (let i = 0; i <= this.cantire-1; i++) {
                        //console.log(this.pedidotemp[i].codprod);
                        this.pedidotemp[i].codclie = this.cliente.codclie
                        this.pedidotemp[i].cliente = this.cliente.descrip
                    }
                //console.log(this.pedidotemp)
            }
        }   
    },
    methods: {      
        borraoitem(iditem){
            this.pedidotemp.splice(iditem,1)
            localStorage.setItem('spx_pedidotemp',JSON.stringify(this.pedidotemp))
            this.cantire = this.pedidotemp.length
            this.calculatotal()
        },
        calculatotal(){
            this.totalre = 0
            this.pedidotemp.forEach((item)=>{
                this.totalre += item.precio*item.cantidad
            })
        },
        guardarPedido(){
            //this.actualizarComentario(this.comentario)
            let pedidospendi = JSON.parse(localStorage.getItem('spx_pedidospend'))
            if(pedidospendi){
                pedidospendi.forEach((pedidoit)=>{
                    this.pedidotemp.push(pedidoit)
                });
            }        
            localStorage.setItem('spx_pedidospend',JSON.stringify(this.pedidotemp))
            localStorage.removeItem('spx_pedidotemp')
            this.pedidotemp = []
            this.cantire = 0
            // this.$router.push('/home');
        },
        totalizarpedido(){
            //this.actualizarComentario(this.comentario)
            let datosDocumento = JSON.stringify(this.pedidotemp);
            axios.post(Global.url+'documento/cerrardocu',datosDocumento,this.headRequest())
            .then((res)=>{
                 //console.log(res)
                 localStorage.removeItem('spx_pedidotemp')
                 this.pedidotemp = []
                 this.cantire = 0
                 //this.$router.push('/home') 
            })
            .catch(function(error){
                console.log(error)
            })
        },
        borrarPedido(){
            localStorage.removeItem('spx_pedidotemp')
            this.cantire = 0;
        },
        /*
        actualizarComentario(textoCo){
            this.pedidotemp.forEach((item)=>{
                item.comentario=textoCo
            })
        },
        */
    },
}
</script>